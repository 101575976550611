/*===> Begin main slider <===*/
.main-slider-wrap {
	position: relative;
	z-index: 10;

	.slick-list, .slick-track {
		height: 100%;
	}

	.slick-dots {
		position: absolute;
		left: 0;
		bottom: 75px;
		width: 100%;
	}

	@include breakpoint(mobile) {
		margin-top: -5px;
		.slick-dots {
			bottom: 25px;
		}
	}
}



.main-slider {
	position: relative;
	height: 917px;
	overflow: hidden;

	.sl-card {
		position: relative;
		display: flex;
		align-items: center;
		height: 100%;
		background-size: cover;
		overflow: hidden;

		&.slick-active {
			.slide-content {
				transform: scale(1);

				img {
					opacity: 1;
					transform: translateY(0);
				}

				.content-text-cursive {
					opacity: 1;
				}

				.content-head {
					opacity: 1;
					transform: translateY(0);
				}

			}
		}

		.container {
			position: relative;
			z-index: 10;
		}
	}

	.slider-bg {
		position: absolute;
		top: 50%;
		left: 50%;
		z-index: 1;
		width: 100%;
		min-width: 1920px;
		height: 100%;
		transform: translate(-50%, -50%);
	}

	.slide-content {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 538px;
		height: 422px;
		margin-left: auto;
		margin-right: -15px;
		padding: 20px;
		text-align: center;
		background-size: cover;
		transform: scale(.85);
		transition: transform .4s;

		img {
			margin-bottom: 15px;
			opacity: 0;
			transform: translateY(-15px);
			transition: opacity .4s, transform .4s;
			transition-delay: .3s;
		}

		.content-text-cursive {
			margin-bottom: 10px;
			font-family: $second_font;
			font-style: italic;
			font-weight: normal;
			font-size: 22px;
			display: flex;
			align-items: flex-end;
			text-align: center;
			color: $text_dark;
			opacity: 0;
			transition: opacity .4s;
			transition-delay: .5s;
		}

		.content-head {
			margin-bottom: 0;
			font-family: $head_font;
			font-style: normal;
			font-weight: 500;
			font-size: 52px;
			line-height: 1.3em;
			color: $head_color;
			opacity: 0;
			transform: translateY(15px);
			transition: opacity .4s, transform .4s;
			transition-delay: .4s;

			span {
				color: $hover_color;
			}
		}
	}

	@include breakpoint(leptop) {
		.slide-content {
			margin-right: 0;
		}
	}

	@include breakpoint(small) {
		height: 575px;
		.slide-content {
			width: 400px;
			height: 314px;

			.content-text-cursive {
				font-size: 18px;
			}

			.content-head {
				font-size: 42px;
			}

			img {
				width: 120px;
			}
		}

		.slider-bg {
			min-width: 100%;
			max-width: none;
			width: auto;
		}
	}

	@include breakpoint(mobile) {
		height: 293px;
		.slide-content {
			width: 262px;
			height: 205px;
			margin-left: auto;
			margin-right: auto;

			.content-text-cursive {
				font-size: 14px;
				margin-bottom: 5px;
			}

			.content-head {
				font-size: 28px;
			}

			img {
				width: 75px;
				margin-bottom: 5px;
			}
		}
	}
}
/*===> End main slider <===*/