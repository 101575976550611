/*===> Begin checkbox <===*/
input[type='checkbox'] {
	position: absolute;
	opacity: 0;
	transform: scale(0);
	margin-bottom: 0;

	&:checked+span {
		&:after {
			content: '\f00c';
			display: flex;
			align-items: center;
			justify-content: center;
			font-family: $font_awsome;
			font-weight: 900;
			font-size: 9px;
			border-color: $main_color;
			color: $main_color;
		}
	}

	&+span {
		position: relative;
		padding-left: 20px;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: $text_color;
		text-transform: capitalize;
		cursor: pointer;
		transition: color .3s;

		&:hover {
			color: $hover_color;
		}

		&:after {
			content: '';
			position: absolute;
			left: 0;
			top: 3px;
			width: 12px;
			height: 12px;
			border: 1px solid $border_color;
		}
	}
}
/*===> End checkbox <===*/