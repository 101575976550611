/*===> Begin pagination <===*/
.pagination {
	display: flex;
	width: 100%;

	li {
		&:last-child {
			margin-right: 0;
		}
	}

	@include breakpoint(extra-small) {
		flex-direction: row;
		li {
			margin-right: 40px;
			margin-bottom: 0px;

			&::after {
				display: block;
			}
		}
	}
}
/*===> End pagination <===*/