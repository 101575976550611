/*===> Begin statement-card <===*/
.statement-card {
	padding: 70px 40px;
	background: #fff;
	box-shadow: 0px 14px 22px rgba(42, 135, 158, 0.14);
	border-radius: 12px;
	text-align: center;

	.statement-ico {
		margin-bottom: 20px;
		max-width: 66px;
		margin-left: auto;
		margin-right: auto;

		img {
			height: 66px;
		}
	}

	h4 {
		margin-bottom: 20px;
	}

	p {
		margin-bottom: 0;
	}

	@include breakpoint(medium) {
		padding: 60px 30px;
	}

	@include breakpoint(extra-small) {
		padding: 30px 20px;

		.statement-ico {
			margin-bottom: 15px;

			img {
				height: 50px;
			}
		}
	}

	@include breakpoint(mobile) {
		.statement-ico {
			margin-bottom: 10px;
		}
	}
}

.statement-list {
	display: flex;
	flex-wrap: wrap;
	margin-left: -15px;
	margin-right: -15px;
	margin-bottom: -30px;

	li {
		width: 33.33%;
		margin-bottom: 0;
		padding-left: 15px;
		padding-right: 15px;

		&::after {
			display: none;
		}

		.statement-card {
			margin-bottom: 30px;
		}
	}

	.slick-dots {
		padding-top: 0;
		display: none;
	}

	@include breakpoint(medium) {
		.slick-dots {
			display: flex;
			margin-bottom: 40px;
			padding-top: 10px;

			li {
				margin-left: 0;
				margin-right: 25px;
			}
		}
	}

	@include breakpoint(small) {
		li {
			width: 50%;
			margin-left: auto;
			margin-right: auto;
		}
	}

	@include breakpoint(extra-small) {
		li {
			width: 100%;
		}
	}
}
/*===> End statement-card <===*/