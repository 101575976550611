/*===> Begin gallery-prewiev-wrap <===*/
.gallery-prewiev-wrap {
	display: flex;
	flex-wrap: wrap;
	margin-right: -15px;
	margin-left: -15px;
	margin-bottom: -15px;

	.gallery-item {
		width: 25%;
		margin-bottom: 15px;
		padding: 0 15px;

		&.mini-gallery {
			display: flex;
			flex-wrap: wrap;
			padding: 0 15px;

			.inner-gallery {
				display: flex;
				flex-wrap: wrap;
				margin-right: -15px;
			}

			.mini-gallery__item {
				width: 50%;
				padding-right: 15px;
				margin-bottom: 15px;

				&:nth-child(3),
				&:nth-child(4) {
					margin-bottom: 0;
				}
			}
		}

		a {
			display: block;
			width: 100%;
			height: 100%;
			border-radius: 12px;
			overflow: hidden;

			img {
				min-height: 100%;
				min-width: 100%;
				max-width: none;
				width: 100%;
			}
		}

		img {
			width: 100%;
		}
	}

	@include breakpoint(extra-small) {
		.gallery-item {
			width: 33.333%;
		}
	}

	@include breakpoint(mobile) {
		.gallery-item {
			width: 50%;

			&:nth-child(odd) {
				padding-right: 7px;
			}

			&:nth-child(even) {
				padding-left: 7px;
			}

			a {
				height: 100%;
			}

			img {
				height: 100%;
			}
		}
	}
}
/*===> End gallery-prewiev-wrap <===*/