/*===> Begin count-list <===*/
.count-section {
	min-height: 548px;
	display: flex;
	align-items: center;
}

.count-list {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
	margin-left: -15px;
	margin-right: -15px;
	margin-bottom: -30px;

	.count-block-wrap {
		padding-left: 15px;
		padding-right: 15px;
		width: 25%;
		&:nth-child(1) {
			transform: rotate(2deg);
		}

		&:nth-child(2) {
			transform: rotate(-1deg);

			.count-numb {
				p {
					color: $component_color_1;
				}
			}
		}

		&:nth-child(3) {
			transform: rotate(2deg);
			.count-numb {
				p {
					color: $component_color_2;
				}
			}
		}

		&:nth-child(4) {
			transform: rotate(-1deg);
			.count-numb {
				p {
					color: $component_color_3;
				}
			}
		}
	}

	@include breakpoint(small) {
		.count-block-wrap {
			width: 50%;
		}
	}
}

.count-block {
	display: block;
	width: 100%;
	max-width: 194px;
	min-height: 170px;
	margin-bottom: 30px;
	margin-left: auto;
	margin-right: auto;
	padding: 25px 15px;
	background: #fff;
	box-shadow: 0px 14px 22px rgba(42, 135, 158, 0.14);
	border-radius: 12px;
	
	&:nth-child(1) {
		transform: rotate(2deg);
	}

	&:nth-child(2) {
		transform: rotate(-1deg);

		.count-numb {
			p {
				color: $component_color_1;
			}
		}
	}

	&:nth-child(3) {
		transform: rotate(2deg);
		.count-numb {
			p {
				color: $component_color_2;
			}
		}
	}

	&:nth-child(4) {
		transform: rotate(-1deg);
		.count-numb {
			p {
				color: $component_color_3;
			}
		}
	}

	.count-numb {
		position: relative;

		p {
			position: absolute;
			top: 50%;
			left: 50%;
			margin-bottom: 0;
			font-family: $head_font;
			font-style: normal;
			font-weight: 500;
			font-size: 60px;
			line-height: 1em;
			color: $main_color;
			transform: translate(-50%, -50%);
		}
	}

	.count-text {
		padding-top: 15px;

		p {
			margin-bottom: 0;
			font-family: $head_font;
			font-style: normal;
			font-weight: 500;
			font-size: 16px;
			text-align: center;
			color: $head_color;
		}
	}
}
/*===> End count-list <===*/