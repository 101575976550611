/*===> Begin program-preview <===*/
.program-preview {
	display: flex;
	margin-bottom: 45px;
	padding: 25px;
	background: #fff;
	border-radius: 12px;
	border: 4px solid $border_testimonial_3;
	box-shadow: 0px 14px 22px rgba(156, 122, 82, 0.14);

	.program-slider-wrap {
		position: relative;
		z-index: 10;
		width: 50%;
		padding: 25px;

		.program-age {
			position: absolute;
			left: 10px;
			top: 45px;
			z-index: 10;
			padding: 6px 15px;
			background: $component_color_1;
			border-radius: 12px;
			box-shadow: 0px 14px 22px rgba(42, 135, 158, 0.14);

			p {
				display: flex;
				align-items: center;
				font-size: 18px;
				color: #fff;
				span {
					margin-right: 10px;
					font-family: $head_font;
					font-style: normal;
					font-weight: 600;
					font-size: 64px;
					line-height: 1em;
					color: $component_color_1_light;
				}
			}
		}

		.program-big-slider {
			margin-bottom: 30px;
			img {
				border-radius: 12px;
				width: 100%;
			}
		}

		.program-nav-slider {
			margin-left: -7px;
			margin-right: -7px;

			.program-slide {
				position: relative;
				padding-left: 7px;
				padding-right: 7px;

				&.slick-current {
					&::after {
						content: '';
						position: absolute;
						top: 0;
						left: 7px;
						z-index: 5;
						width: calc(100% - 14px);
						height: 100%;
						border-radius: 12px;
						border: 2px solid  $main_color;
					}

					&::before {
						content: '';
						position: absolute;
						top: 0;
						left: 7px;
						z-index: 3;
						width: calc(100% - 14px);
						height: 100%;
						border-radius: 12px;
						border: 4px solid  #fff;
					}
				}
			}

			img {
				border-radius: 12px;
				width: 100%;
			}
		}
	}

	.program-description {
		position: relative;
		padding: 25px;

		&::after {
			content: attr(data-text);
			position: absolute;
			bottom: 0;
			right: 0;
			z-index: 0;
			font-family: $head_font;
			font-style: normal;
			font-weight: 600;
			font-size: 160px;
			line-height: 1em;
			text-align: right;
			color: $border_testimonial_3;
			opacity: .5;
		}

		.program-heaer {
			position: relative;
			z-index: 10;
			display: flex;
			align-items: flex-end;
			justify-content: space-between;

			h3 {
				margin-bottom: 35px;
				line-height: 1em;
			}

			p {
				margin-bottom: 35px;
			}
		}

		.program-text {
			position: relative;
			z-index: 10;
			p {
				margin-bottom: 25px;
			}

			ul {
				margin-bottom: 0;
				li {
					p {
						margin-bottom: 0;
					}
				}
			}
		}
	}

	@include breakpoint(small) {
		flex-direction: column;

		.program-slider-wrap {
			width: 100%;
		}

		.program-description {
			width: 100%;

			&::after {
				font-size: 130px;
			}

			.program-heaer {
				h3 {
					margin-bottom: 30px;
				}
			}
		}
	}

	@include breakpoint(extra-small) {
		margin-bottom: 35px;

		.program-slider-wrap {
			padding-bottom: 10px;

			.program-age {
				p {
					span {
						font-size: 46px;
					}
				}
			}
		}

		.program-description {
			&::after {
				font-size: 100px;
			}
		}
	}

	@include breakpoint(mobile) {
		padding: 0;

		.program-slider-wrap {

			.program-age {
				top: 15px;
				p {
					font-size: 16px;
					span {
						font-size: 30px;
					}
				}
			}
		}

		.program-description {
			&::after {
				right: auto !important;
				left: 50% !important;
				transform: translateX(-50%);
				font-size: 45px;
			}

			.program-heaer {
				flex-direction: column;
				align-items: flex-start;

				h3 {
					margin-bottom: 20px;
				}

				p {
					margin-bottom: 20px;
				}
			}

			.program-text {
				p {
					margin-bottom: 15px;
				}
			}

			ul {
				li {
					width: 100%;
				}
			}
		}
	}
}
/*===> End program-preview <===*/