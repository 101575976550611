/*===> Begin advantage-list <===*/
.advantage-list {
	display: flex;
	flex-wrap: wrap;
	margin-left: -15px;
	margin-right: -15px;
	margin-bottom: -65px;

	&.advantage-second {
		margin-bottom: -30px;
		li {
			width: 25%;
			margin-bottom: 30px;
			padding-top: 45px;
			padding-left: 15px;

			&::before {
				top: 0;
				left: 50%;
				transform: translateX(-50%);
				opacity: .14;
				font-size: 110px;
				line-height: 1em;
			}

			&::after {
				content: none !important;
				display: none !important;
			}

			.advantage-block {
				h5 {
					text-align: center;
				}

				p {
					text-align: center;
				}
			}
		}
	}

	li {
		position: relative;
		width: 33.33%;
		margin-bottom: 60px;
		padding-left: 70px;
		padding-right: 15px;

		&::before {
			position: absolute;
			z-index: 4;
			top: 0;
			left: 15px;
			margin: 0;
			font-size: 32px;
		}

		&:nth-child(odd) {
			&::after {
				content: '';
				position: absolute;
				z-index: 1;
				top: -20px;
				left: 0%;
				width: 100%;
				height: 192px;
				background: url(../img/cloudx2.png);
				background-size: 100% auto;
				background-repeat: no-repeat;
				transform: rotateY(180deg);
			}
		}

		.advantage-block {
			position: relative;
			z-index: 10;
		}

		h5 {
			margin-bottom: 15px;
			font-weight: 500;
			color: $head_color;
		}

		p {
			margin-bottom: 0;
		}
	}

	@include breakpoint(small) {
		margin-bottom: -40px;

		&.advantage-second {
			li {
				width: 50%;
			}
		}

		li {
			width: 50%;
			margin-bottom: 30px;

			&:nth-child(odd) {
				&:after {
					display: none;
				}
			}

			&:nth-child(1),&:nth-child(4),&:nth-child(5) {
				&::after {
					content: '';
					position: absolute;
					z-index: 1;
					top: -20px;
					display: block !important;
					left: 0%;
					width: 100%;
					height: 192px;
					background: url(../img/cloudx2.png);
					background-size: 100% auto;
					background-repeat: no-repeat;
					transform: rotateY(180deg);
				}
			}
		}
	}

	@include breakpoint(extra-small) {
		li {
			// width: 100%;

			&::after {
				left: auto !important;
				right: 0 !important;
				max-width: 320px !important;
				// background-size: auto 100% !important;
				background-position: center center !important;
			}
		}
	}

	@media (max-width: 550px) {
		&.advantage-second {
			li {
				margin-bottom: 35px;
			}
		}

		li {
			width: 100%;

			&:nth-child(1),&:nth-child(4),&:nth-child(5) {
				&::after {
					display: none !important;
				}
			}

			&:nth-child(odd) {
				&::after {
					content: '';
					position: absolute;
					z-index: 1;
					top: -20px;
					left: 0%;
					right: auto;
					display: block !important;
					width: 60%;
					height: 192px;
					background: url(../img/cloudx2.png);
					background-size: 100% auto;
					background-repeat: no-repeat;
					transform: rotateY(180deg);
				}
			}

			&:nth-child(1),&:nth-child(5) {
				&::after {
					left: 20px !important;
				}
			}
		}
	}

	@include breakpoint(mobile) {
		&.advantage-second {
			li {
				margin-bottom: 35px;
				width: 100%;
			}
		}
	}
}

.adventage-slider {
	.slick-dots {
		padding-top: 0;
		display: none;
	}

	@include breakpoint(medium) {
		.slick-dots {
			display: flex;
			padding-top: 0;
		}
	}

	@include breakpoint(extra-small) {
		.slick-dots {
			margin-bottom: 30px;
			padding-top: 10px;
		}
	}
}
/*===> End advantage-list <===*/