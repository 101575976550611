/*===> Begin news-block <===*/
.news-block {
	max-width: 450px;
	margin-left: auto;
	margin-right: auto;
	border-radius: 12px;
	box-shadow: 0px 10px 22px rgba(42, 135, 158, 0.14);
	overflow: hidden;

	.news-block__img {
		display: block;

		img {
			width: 100%;
		}
	}

	.news-block-description {
		padding: 40px 30px 40px;
		.news-block-description__main-info {
			display: flex;
			margin-bottom: 10px;

			p {
				margin-right: 20px;
				margin-bottom: 20px;
				font-size: 14px;

				&:last-child {
					margin-right: 0;
				}

				&.news-author {
					font-style: italic;
				}
			}
		}

		.news-block-description__short-text {
			h4 {
				margin-bottom: 20px;
				a {
					color: $head_color;

					&:hover {
						color: $hover_color;
					}
				}
			}

			p {
				margin-bottom: 30px;
			}

			time {
				display: block;
				font-size: 14px;
				color: $main_color;
			}
		}
	}

	@include breakpoint(small) {
		.news-block-description {
			padding: 35px 30px 35px;

			.news-block-description__main-info {
				margin-bottom: 0;
			}

			.news-block-description__short-text {
				p {
					margin-bottom: 20px;
				}
			}
		}
	}

	@include breakpoint(mobile) {
		.news-block-description {
			padding: 33px 30px 33px;
			.news-block-description__main-info {
				margin-bottom: 0;
			}

			.news-block-description__short-text {
				h4 {
					margin-bottom: 15px;
				}

				p {
					margin-bottom: 20px;
				}
			}
		}
	}
}
/*===> End news-block <===*/