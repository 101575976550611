/*===> Begin form controls <===*/
input {
	display: block;
	width: 100%;
	margin-bottom: 20px;
	padding: 20px 0;
	background-color: transparent;
	border: none;
	border-bottom: 1px solid $border_color;
	font-family: $main_font;
	font-style: normal;
	font-weight: normal;
	font-size: 15px;
	line-height: 15px;
	color: $text_color;

	@include breakpoint(small) {
		font-size: 14px;
	}
}

.input-area {
	display: flex;
	flex-wrap: wrap;
	margin-left: -15px;
	margin-right: -15px;

	.input-wrap {
		width: 50%;
		padding: 0 15px;

		input {
		}
	}

	@include breakpoint(mobile) {
		.input-wrap {
			width: 100%;
		}
	}
}

textarea {
	display: block;
	width: 100%;
	height: 150px;
	margin-bottom: 20px;
	padding: 20px 0;
	background-color: transparent;
	border: none;
	border-bottom: 1px solid $border_color;
	font-family: $main_font;
	font-style: normal;
	font-weight: normal;
	font-size: 15px;
	line-height: 15px;
	color: $text_color;
	resize: none;

	@include breakpoint(small) {
		font-size: 14px;
	}
}

.successform {
	display: none;
	p {
		font-weight: 500;
		color: $main_color;
	}
}

.errorform {
	display: none;
	p {
		font-weight: 500;
		color: $error_color;
	}
}

label.error {
	display: block;
	font-size: 12px;
	color: $error_color;
}
/*===> End form controls <===*/




.select select {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  outline: 0;
  box-shadow: none;
  border: 0 !important;
  background: transparent;
  background-image: none;
}
/* Remove IE arrow */
.select select::-ms-expand {
  display: none;
}
/* Custom Select */
.select {
  position: relative;
  display: flex;
  width: 100%;
  height: 58px;
  line-height: 3;
  font-size: 15px;
  line-height: 15px;
  background: transparent;
  overflow: hidden;
  border-bottom: 1px solid #CEDDE0;
}
.select select {
  flex: 1;
  padding: 0;
  color: #777;
  cursor: pointer;
}
.select select option{
	padding-left: 10px;
}
/* Arrow */
.select::after {
  content: '';
  position: absolute;
  top: 25px;
  right: 0;
  background: transparent;
  cursor: pointer;
  pointer-events: none;
  font-size: 15px;
  -webkit-transition: .25s all ease;
  -o-transition: .25s all ease;
  transition: .25s all ease;
  border: 7px solid transparent;
  border-top: 7px solid #CEDDE0;
}
/* Transition */
.select:hover::after {
  color: #f39c12;
}


// <div class="select">
//   <select name="slct" id="slct">
//     <option selected disabled>Choose an option</option>
//     <option value="1">Pure CSS</option>
//     <option value="2">No JS</option>
//     <option value="3">Nice!</option>
//   </select>
// </div>