/*===> Begin download list <===*/
.download-list {
	p {
		display: flex;
		justify-content: space-between;
		margin-bottom: 15px;

		&:last-child {
			margin-bottom: 0;
		}

		span {
			font-family: $main_font;
			font-style: normal;
			font-weight: 500;
			font-size: 15px;
			color: $head_color;

			img {
				margin-right: 15px;
			}
		}

		a {
			font-family: $main_font;
			font-style: normal;
			font-weight: 600;
			font-size: 14px;
			color: $hover_color;

			img {
				margin-right: 10px;
			}
		}
	}

	@include breakpoint(small) {
	    p {
	    	span {
	    		font-size: 14px;
	    	}
	    }
	}

	@include breakpoint(mobile) {
		p {
			span {
				font-size: 14px;

				img {
					margin-right: 10px;
				}
			}

			a {
				text-align: center;
				font-size: 12px;

				img {
					margin-right: 5px;
				}
			}
		}
	}
}
/*===> End download list <===*/